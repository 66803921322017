import React from 'react'
import { Nav } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'

import { useMenu } from '../hooks/useMenu'
import { useAuth } from '../hooks/useAuth'

import Footer from './Footer'
import './SidebarMenu.css'

const defaultMenuIcon = 'LocationDot'
const iconStyle = {
    styles: {
        root: {
            margin: '0 8px',
        },
    },
}

const SidebarMenu = (props) => {
    const navigate = useNavigate()
    const { accessToken, currentAccount } = useAuth()
    const { menu, expanded, toggleMenu, loadMenu } = useMenu()

    console.log('Sidebar account', currentAccount)

    React.useEffect(() => {
        loadMenu(currentAccount ? currentAccount.account._id : null)
    }, [accessToken])

    const onLinkClick = (e, item) => {
        if (item.key == '') {
            toggleMenu()
            return
        }
        e.preventDefault()
        // If an external link, open in blank tab
        if (item.url.match(/^https?:\/\//g)) {
            window.open(item.url, '_blank')
        } else {
            navigate(item.url)
        }
    }

    const menuData = {
        groups: [
            {
                links: menu
                    ? menu.items.map(
                          ({ name, link, icon = defaultMenuIcon }) => {
                              return {
                                  name,
                                  key: name,
                                  url: link,
                                  iconProps: {
                                      iconName: icon,
                                      ...iconStyle,
                                  },
                              }
                          },
                      )
                    : [],
            },
        ],
        expandedText: 'expanded',
        collapsedText: 'collapsed',
    }
    // if (!accessToken || authLoading || loading || !menu) {
    //     return <div>Loading...</div>
    // }

    const footer = expanded ? <Footer /> : null

    return (
        <div
            id='sidebar'
            style={{
                flexBasis: expanded ? '24em' : 42,
                flexDirection: 'column',
            }}>
            <Nav
                groups={menuData.groups}
                expandedStateText={menuData.expandedText}
                collapsedStateText={menuData.collapsedText}
                onLinkClick={onLinkClick}
                styles={{
                    groupContent: { animation: 'none' },
                    root: { width: '24em' },
                }}
                style={{ flexGrow: 1 }}
            />
            {footer}
        </div>
    )
}

export default React.memo(SidebarMenu)
