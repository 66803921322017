import React from 'react'

import {
    Modal,
    Stack,
    Dropdown,
    IconButton,
    PrimaryButton,
    DefaultButton,
    TextField,
    Icon,
} from '@fluentui/react'

import { assignTransaction, uploadTransaction } from '@/api'
import { useAuth } from '@/hooks/useAuth'
import FileUploadInput from '../../../components/FileUploadInput'

const SubmitModal = ({
    isOpen,
    onClose = () => {},
    loadData = () => {},
    transaction = {},
}) => {
    const currentStage = transaction.stages[transaction.currentStage]

    const [sendTo, setSendTo] = React.useState(null)
    const [comment, setComment] = React.useState('')

    const [files, setFiles] = React.useState([])

    const onChangeFiles = (event) => {
        setFiles(Array.from(event.target.files))
    }

    const submitItems = currentStage.roles.map((role) => {
        const assignedRole = transaction.roles.find(({ name }) => name === role)

        const text = assignedRole
            ? `${assignedRole.user.firstName} ${assignedRole.user.lastName} [${role}]`
            : role

        return {
            key: role,
            text,
            disabled: !assignedRole,
        }
    })

    const onChange = (e, item, i) => {
        setSendTo(item.key)
    }

    const onChangeComment = (e) => {
        setComment(e.target.value)
    }

    const onSend = async () => {
        if (files.length) {
            // upload files
            const formData = new FormData()
            files.forEach((file) => formData.append('file', file))
            await uploadTransaction(transaction._id, formData)
        }

        const payload = { comment }

        await assignTransaction(transaction._id, sendTo, payload)

        // Close modal and reload data
        onClose()
        loadData()
    }

    return (
        <Modal
            isOpen={isOpen}
            style={{ padding: 50 }}
            onDismiss={onClose}
            isBlocking={true}>
            <Stack
                horizontalAlign='center'
                style={{ padding: '1em' }}
                tokens={{ childrenGap: '1em' }}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}>
                    <h1 style={{ flexGrow: 1, margin: 0 }}>Send</h1>
                    <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel='Close'
                        onClick={onClose}
                    />
                </div>
                <Dropdown
                    label='Send to'
                    options={submitItems}
                    styles={{ dropdown: { width: 300 } }}
                    onChange={onChange}
                    defaultValue={sendTo}
                />
                <TextField
                    label='Comments'
                    rows={3}
                    multiline
                    styles={{ root: { width: 300 } }}
                    value={comment}
                    onChange={onChangeComment}
                />
                <FileUploadInput
                    files={files}
                    onChange={onChangeFiles}
                    buttonText='Attach Files'
                />
                <Stack
                    horizontal
                    style={{ width: '100%' }}
                    tokens={{ childrenGap: '1em' }}
                    horizontalAlign='end'>
                    <PrimaryButton onClick={onSend}>Send</PrimaryButton>
                    <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                </Stack>
            </Stack>
        </Modal>
    )
}

const FilesInput = () => {
    return (
        <Stack horizontalAlign='start'>
            <a>
                <Icon iconName='Attach' /> Attach Files
            </a>
        </Stack>
    )
}

export default SubmitModal
