import React from 'react'

import { Route, Routes, Navigate, Outlet } from 'react-router-dom'

import Homepage from './views/Homepage'
import TransactionList from './views/TransactionList/TransactionList.js'
import Transaction from './views/Transaction/index.js'
import TransactionHistory from './views/TransactionHistory'
import Compare from './views/Compare'
import CreateTransaction from './views/CreateTransaction'
import Template from './views/Template'
import Dashboard from './views/Dashboard'

// Auth routes
import Signup from './views/auth/Signup'
import Invite from './views/auth/Invite'
import Login from './views/auth/Login'
import LoginAuth from './views/auth/LoginAuth'
import Logout from './views/auth/Logout'
import NotFound from './views/NotFound'
import ResetPassword from './views/auth/ResetPassword'
import Test from './views/Test'
import TestError from './views/TestError'
import Profile from './views/Profile'

import Import from './views/Import'

// Admin routes
import Accounts from './views/admin/Accounts'
import Account from './views/admin/Account'
import Templates from './views/admin/Templates'
import Workflows from './views/admin/Workflows'

import { useAuth } from './hooks/useAuth'
import { useLocation } from 'react-router-dom'
import AdminIndex from './views/admin/AdminIndex'
import UserSwitch from './views/UserSwitch.js'
import Portal from './views/Portal.js'

const PrivateRoute = () => {
    const { loggedIn, userSwitch = false, onRouteChange } = useAuth()
    const { pathname } = useLocation()
    React.useEffect(() => {
        onRouteChange()
    }, [pathname])
    if (userSwitch) {
        return <UserSwitch userSwitch={userSwitch} />
    }
    return loggedIn ? <Outlet /> : <Navigate to={`/login?after=${pathname}`} />
}

const AppRoutes = () => (
    <Routes>
        <Route path='/' exact element={<Homepage />} />
        <Route path='/signup' exact element={<Signup />} />
        <Route path='/login' exact element={<Login />} />
        <Route path='/auth' exact element={<LoginAuth />} />
        <Route path='/logout' exact element={<Logout />} />
        <Route path='/invite' exact element={<Invite />} />
        <Route
            exact
            path='/portal/:transactionId/:stage'
            element={<Portal />}
        />
        <Route element={<PrivateRoute />}>
            <Route exact path='/resetpassword' element={<ResetPassword />} />
            <Route exact path='/dashboard' element={<Dashboard />} />
            <Route exact path='/transactions' element={<TransactionList />} />
            <Route
                exact
                path='/transactions/:transactionId'
                element={<Transaction />}
            />
            <Route
                exact
                path='/createtransaction'
                element={<CreateTransaction />}
            />
            <Route
                exact
                path='/transactionhistory/:transactionId'
                element={<TransactionHistory />}
            />
            <Route exact path='/compare/:transactionId' element={<Compare />} />
            <Route
                exact
                path='/template/:templateName'
                element={<Template />}
            />
            <Route
                exact
                path='/template/:templateName/:entityId'
                element={<Template />}
            />
            <Route exact path='/logout' element={<Logout />} />
            <Route exact path='/profile' element={<Profile />} />
            <Route exact path='/profile/:section' element={<Profile />} />
            <Route
                exact
                path='/profile/:section/:subSection'
                element={<Profile />}
            />

            <Route exact path='/import' element={<Import />} />

            {/* Test Routes */}
            <Route exact path='/test' element={<Test />} />
            <Route exact path='/test-error' element={<TestError />} />

            <Route exact path='/admin/accounts' element={<Accounts />} />
            <Route
                exact
                path='/admin/account/:accountId'
                element={<Account />}
            />
            <Route exact path='/admin/templates' element={<Templates />} />
            <Route exact path='/admin/workflows' element={<Workflows />} />
            <Route exact path='/admin' element={<AdminIndex />} />
            <Route path='*' element={<NotFound />} />
        </Route>
    </Routes>
)

export default AppRoutes
