import dayjs from 'dayjs'

const getFieldByDataVal = (schemaItems, value) => {
    const upperCaseDataVal = value.toUpperCase()
    return schemaItems.find(
        ({ value }) => value.toUpperCase() === upperCaseDataVal,
    )
}

const lookupValue = (fieldSchema, value) => {
    if (!value) {
        return ''
    }

    // Format dates
    if (fieldSchema.type === 'DATE') {
        const { format = 'dmy' } = fieldSchema
        if (format === 'dm') {
            return dayjs(value).format('DD MMMM')
        }
        if (format === 'my') {
            return dayjs(value).format('MMMM YYYY')
        }
        return dayjs(value).format('DD MMMM YYYY')
    }

    if (fieldSchema.type !== 'LIST') {
        return value
    }

    if (!fieldSchema.items) {
        return 'Invalid Value'
    }

    const field = getFieldByDataVal(fieldSchema.items, value)
    if (!field) {
        return 'Invalid Value'
    }
    return field.title
}

export { lookupValue }
