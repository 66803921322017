import React from 'react'
import {
    Stack,
    TextField,
    PrimaryButton,
    Icon,
    Text,
    Persona,
    PersonaSize,
    Spinner,
    SpinnerSize,
    CommandBar,
    DefaultButton,
    IconButton,
} from '@fluentui/react'
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons'
import { capitalize } from '../../../lib/string'
import { downloadTransactionFile, nudgeTransaction } from '../../../api'
import { useNavigate } from 'react-router-dom'
import ApproveStageOverview from './ApproveStageOverview.js'
import Template from '../../../components/template/Template'

import RollbackModal from '../RollbackModal.js'
import { useAuth } from '../../../hooks/useAuth'

import { getSetting } from '../../../api/config'
import RoleAssignPanel from '../../../views/Transaction/Roles/RoleAssignPanel'
import { getRolesData } from '../../../lib/roles'

const API_BASE = getSetting('API_BASE')

const ApproveStage = ({
    transaction,
    transactionFiles = [],
    loadData,
    transactionHistory = [],
    template = null,
    fields = [],
}) => {
    const { userId } = useAuth()
    const navigate = useNavigate()
    const [data, setDataRaw] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [serverError, setServerError] = React.useState(null)
    const [rollbackModelOpen, setRollbackModelOpen] = React.useState(false)

    const setValue = (signer, field, value) => {
        setDataRaw((dataIn) => {
            const data = { ...dataIn }
            if (!data[signer]) {
                data[signer] = {}
            }

            data[signer][field] = value
            return data
        })
    }

    const getValue = (signer, field) =>
        data[signer] ? data[signer][field] || '' : ''

    const hasRole = (role) =>
        transaction.roles.find(({ name }) => name === role)

    const findFile = ({ document }) =>
        transactionFiles.find(
            (transactionFile) => transactionFile.filename === document,
        )

    const currentStage = transaction.stages[transaction.currentStage] || {}
    console.log('transactionHistory', transactionHistory)
    const lastSave = transactionHistory.slice(-1)[0]
    const approveLastSave =
        lastSave?.type === 'approve' &&
        lastSave?.stage === transaction.currentStage &&
        lastSave?.status === 'valid'
    const approveData = lastSave.approveData

    console.log('approveData', approveData, lastSave)

    let content
    const status = approveLastSave ? approveData.status : ''
    const documents = currentStage.documents
    const signers = approveLastSave ? approveData.signers : currentStage.signers

    console.log(signers)
    const signersData = Object.keys(signers || {}).map((signer) => {
        const who = signers[signer]
        if (who.role) {
            return {
                role: true,
                hasRole: hasRole(who.role),
                who: who.role,
            }
        }
    })

    // const isSigner = Array.isArray(signers)
    //     ? signers.find((signer) => signer.userId === userId)
    //     : false
    const hasSentEmailStatus = Array.isArray(signers)
        ? signers.find(
              (signer) => signer.userId === userId && signer.status === 'sent',
          )
        : false

    const showApprovalButtons = hasSentEmailStatus

    console.log(
        'currentStage.documents',
        currentStage.documents,
        transactionFiles,
    )

    const onSubmit = () => {
        if (loading) return
        const files = currentStage.documents
            .map(findFile)
            .filter((a) => !!a)
            .map(({ filename, extension, upload }) => {
                return { filename, extension, upload }
            })

        const payload = {
            type: 'approve',
            data: {
                signers: data,
                files,
            },
        }
        setLoading(true)
        nudgeTransaction(transaction._id, payload)
            .then(() => loadData())
            .catch((err) => {
                setLoading(false)
                setServerError({
                    title: 'Error sending Approve envelope',
                    details: err,
                })
            })
    }

    const onApprove = (status) => {
        const payload = {
            type: 'approve-approval',
            data: {
                status,
                envelopeId: lastSave.approveData.envelopeId,
            },
        }
        setLoading(true)
        nudgeTransaction(transaction._id, payload)
            .then(() => loadData())
            .catch((err) => {
                setLoading(false)
                setServerError({
                    title: 'Error sending Approve Approval',
                    details: err,
                })
            })
    }

    if (['sent', 'declined'].includes(status)) {
        content = (
            <Stack
                tokens={{ childrenGap: 10 }}
                style={{ width: 700 }}
                horizontalAlign='center'>
                <h1>Sent for approval</h1>
                <Template
                    contents={template.content.contents}
                    readonly={true}
                    style={{ minWidth: 700 }}
                />
                <ApprovalDocuments
                    documents={documents}
                    transactionFiles={transactionFiles}
                />
                <p>Please check back later</p>
                <ApproveStageOverview docusignData={approveData} />

                {showApprovalButtons ? (
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <PrimaryButton
                            onClick={onApprove.bind(null, 'approve')}>
                            Approve
                        </PrimaryButton>
                        <DefaultButton onClick={onApprove.bind(null, 'reject')}>
                            Reject
                        </DefaultButton>
                    </Stack>
                ) : null}

                <Stack
                    style={{ color: '#aaa' }}
                    tokens={{ childrenGap: 10 }}
                    horizontalAlign='center'>
                    <p>Envelope ID: {approveData.envelopeId}</p>
                    {approveData.updated ? (
                        <p>Last Updated: {lastSave?.updated}</p>
                    ) : null}
                </Stack>
            </Stack>
        )
    } else {
        const currentRole = transaction.roles.find(
            ({ name }) => name === transaction.currentRole,
        )

        const isWithCurrentUser = currentRole
            ? userId === currentRole.user._id
            : false
        console.log('isWithCurrentUser', isWithCurrentUser)

        content = (
            <Stack
                tokens={{ childrenGap: 10 }}
                style={{ width: 700 }}
                verticalAlign='center'>
                <Template
                    contents={template.content.contents}
                    readonly={true}
                />
                <ApprovalDocuments
                    documents={documents}
                    transactionFiles={transactionFiles}
                />
                <Stack tokens={{ childrenGap: 10 }}>
                    <Text>Approvers:</Text>
                    {signersData.map((signer, i) => {
                        return (
                            <Signer
                                key={i}
                                signer={signer}
                                roles={transaction.roles}
                                setValue={setValue}
                                getValue={getValue}
                                transaction={transaction}
                                loadData={loadData}
                                readonly={!isWithCurrentUser}
                            />
                        )
                    })}
                </Stack>
                {isWithCurrentUser ? (
                    <PrimaryButton disabled={loading} onClick={onSubmit}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            {loading ? (
                                <Spinner size={SpinnerSize.small} />
                            ) : null}
                            <span>{loading ? 'Sending' : 'Send'}</span>
                        </Stack>
                    </PrimaryButton>
                ) : null}
                {serverError ? (
                    <p style={{ color: 'red' }}>{serverError.title}</p>
                ) : null}
            </Stack>
        )
    }

    const actions = [
        {
            key: 'back',
            text: 'Back',
            iconProps: { iconName: 'Back' },
            onClick: () => {
                navigate(-1)
            },
        },
        {
            key: 'rollback',
            text: 'Rollback',
            iconProps: { iconName: 'Rewind' },
            onClick: () => {
                setRollbackModelOpen(true)
            },
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: () => {
                loadData()
            },
        },
    ]

    return (
        <>
            <CommandBar items={actions} />
            <Stack horizontalAlign='center' style={{ minHeight: 500 }}>
                <Text variant='xxLarge'>{currentStage.title || 'Approve'}</Text>
                {content}
            </Stack>
            <RollbackModal
                isOpen={rollbackModelOpen}
                onClose={() => setRollbackModelOpen(false)}
                transaction={transaction}
            />
        </>
    )
}

const ApprovalDocuments = ({ documents = [], transactionFiles = [] }) => {
    console.log('transactionFiles', transactionFiles)
    const findFile = ({ document }) =>
        transactionFiles.find(
            (transactionFile) => transactionFile.filename === document,
        )
    return (
        <>
            <Text>Documents for approval:</Text>
            <Stack style={{ width: '100%' }}>
                {documents.map((document, i) => {
                    return (
                        <Document
                            key={i}
                            document={document}
                            uploadDocument={findFile(document)}
                        />
                    )
                })}
            </Stack>
        </>
    )
}

const Signer = ({
    signer,
    roles,
    setValue,
    getValue,
    transaction,
    loadData = () => {},
    readonly = false,
}) => {
    const title = capitalize(signer.who)

    const [assignPanelOpen, setAssignPanelOpen] = React.useState(false)

    const getRole = (role) => {
        return roles.find(({ name }) => name === role)
    }

    if (signer.role && signer.hasRole) {
        const role = getRole(signer.who)
        const user = role.user
        return (
            <Stack
                horizontal
                tokens={{ childrenGap: 10 }}
                horizontalAlign='space-between'>
                <Text variant='large'>{title}:</Text>
                <Persona
                    text={`${user.firstName} ${user.lastName}`}
                    size={PersonaSize.size24}
                />
            </Stack>
        )
    } else if (signer.role) {
        const role = getRolesData(transaction).find(
            (role) => role.role === signer.who,
        )
        return (
            <div>
                <Text variant='large' block>
                    {title}
                </Text>
                {!readonly ? (
                    <>
                        <DefaultButton onClick={setAssignPanelOpen}>
                            Assign Role
                        </DefaultButton>
                        <RoleAssignPanel
                            transactionId={transaction._id}
                            open={assignPanelOpen}
                            roles={[role]}
                            setOpenFalse={() => setAssignPanelOpen(false)}
                            loadData={loadData}
                        />
                    </>
                ) : null}
            </div>
        )
    } else if (signer.email) {
        return (
            <div>
                <Text variant='large'>{title}</Text>
            </div>
        )
    }

    return null
}

const documentStyle = {
    border: '2px solid lightgrey',
    padding: 10,
    marginBottom: 5,
    borderRadius: 5,
}

const Document = ({ document, uploadDocument, downloadLink = true }) => {
    console.log('DOCUMENT', document, uploadDocument)
    const [filename, extension] = parseFilename(document.document)

    return (
        <Stack style={documentStyle}>
            <File
                filename={filename}
                extension={extension}
                downloadLink={downloadLink}
                uploadDocument={uploadDocument}
            />
        </Stack>
    )
}

// const SignersList = ({ document, signers }) => {
//     const content = document.signers.map((signer, i) => {
//         return <span key={i}>{capitalize(signers[signer].role)}</span>
//     })
//     return (
//         <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign='center'>
//             <Text variant='small'>Signed by: </Text>
//             <Stack horizontal tokens={{ childrenGap: 5 }}>
//                 {content}
//             </Stack>
//         </Stack>
//     )
// }

const File = ({
    filename,
    extension = 'txt',
    downloadLink = true,
    uploadDocument,
}) => {
    const { refreshAuth } = useAuth()
    console.log(uploadDocument)
    const onClickDownload = () => {
        const { filename, upload } = uploadDocument

        refreshAuth().then((tokens) => {
            const documentUrl = `${API_BASE}/document/upload.docx?token=${tokens.accessToken}&filename=${filename}&upload=${upload}&locked=true`
            window.open(documentUrl, '_blank')
        })
    }
    return (
        <Stack
            horizontal
            verticalAlign='center'
            tokens={{ childrenGap: 10 }}
            style={{ width: '100%' }}
            horizontalAlign='start'>
            <Icon
                {...getFileTypeIconProps({
                    extension,
                    size: 24,
                    imageFileType: 'png',
                })}
            />
            <Text>{filename}</Text>
            {downloadLink ? (
                <IconButton
                    iconProps={{ iconName: 'Download' }}
                    onClick={onClickDownload}></IconButton>
            ) : null}
        </Stack>
    )
}

const parseFilename = (filename) => {
    const parts = filename.split('.')
    return [parts.slice(0, -1).join('.'), parts.slice(-1)[0]]
}

export default ApproveStage
