import React from 'react'

import { CommandBar, Stack, Text, Toggle, StackItem } from '@fluentui/react'

import { formatDateTime } from '../../lib/date'
import { getValues } from '../../lib/transaction'
import { reduceTemplate } from '../../lib/template'

import Pipeline from '../../components/Pipeline'
import Template from '../../components/template/Template'
import { FormProvider } from '../../components/controls/useForm'

import { useNavigate } from 'react-router-dom'
import { useStateMachine } from '../../hooks/useStateMachine.js'

import { useAuth } from '../../hooks/useAuth'

import { getTemplate } from '../../api'
import { getSetting } from '../../api/config'
import DocusignOverview from '../../components/template/Docusign/DocusignOverview'
import PipelineC from '../../lib/pipeline/Pipeline'

const noop = () => {}

const cloneObject = (obj) => {
    if (obj === null || typeof obj !== 'object' || 'isActiveClone' in obj) {
        return obj
    }

    const temp = obj instanceof Date ? new obj.constructor() : obj.constructor()

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            obj['isActiveClone'] = null
            temp[key] = cloneObject(obj[key])
            delete obj['isActiveClone']
        }
    }
    return temp
}

class Values {
    constructor() {
        this.data = {}
    }

    clear() {
        this.data = {}
    }

    applyHistory(transactionHistory, stageId = null) {
        const allValues = {}
        for (const history of transactionHistory) {
            Object.assign(allValues, getValues(history))
            if (history._id === stageId) {
                break
            }
        }

        const trimmedValues = Object.entries(allValues).reduce(
            (acc, [key, value]) => {
                if (value.length) acc[key] = value
                return acc
            },
            {},
        )

        this.data = trimmedValues
    }

    toData() {
        return Object.keys(this.data).map((key) => {
            return {
                field: key,
                values: this.data[key],
            }
        })
    }
}

const API_BASE = getSetting('API_BASE')
const TransactionOverview = ({
    transaction,
    template,
    transactionHistory,
    fields = [],
    loadData = noop,
}) => {
    const { refreshAuth } = useAuth()
    const navigate = useNavigate()
    const [hideArchive, setHideArchive] = React.useState(true)
    const [showHidden, setShowHidden] = React.useState(false)

    const [activeStage, setActiveStage] = React.useState(null)
    const [selected, setSelected] = React.useState(new Set())

    // Pipeline data
    const pipeline = new PipelineC(transaction, transactionHistory, hideArchive)
    const pipelineData = pipeline.toPipeline()
    console.log('PIPELINE', pipeline, pipelineData)

    const toggleSelected = (stageId) => {
        const newSelected = new Set(selected)
        if (newSelected.has(stageId)) {
            newSelected.delete(stageId)
        } else {
            newSelected.add(stageId)
        }
        setSelected(newSelected)
        return newSelected
    }

    const onClickRow = (item) => {
        const clearSelected = false
        console.log('IM HERE', item, item)
        if (item.data.type === 'stage') {
            setSelected(new Set())
            setActiveStage(item)
            return
        }

        const stageId = item.id
        setActiveStage(null)

        const newSelected = new Set()
        if (!clearSelected) {
            newSelected.add(stageId)
        }
        setSelected(newSelected)
    }

    const onSelected = (item) => {
        const stageId = item.id
        console.log('onSelected')
        // Accumulate all changes up to the selected point,
        // show all changes.
        toggleSelected(stageId)
    }

    console.log('SELECTED', selected)
    let showDetails = false
    let transactionData
    if (selected.size === 1) {
        transactionData = transactionHistory.find(({ _id }) =>
            selected.has(_id),
        )

        if (transactionData) {
            showDetails = 'save-details'
        }
    }
    if (!showDetails) {
        if (activeStage !== null) {
            showDetails = 'stage-details'
        }
    }

    const onDocumentView = () => {
        refreshAuth().then((tokens) => {
            const selectedId = Array.from(selected)[0]
            const selectedHistory = transactionHistory.find(
                (trx) => trx._id === selectedId,
            )

            const documentTemplateStage = transaction.workflow.stages.find(
                (stage) =>
                    stage.templates.find(
                        (template) => template._id === selectedHistory.template,
                    ),
            )
            const filename = `${documentTemplateStage.templates[0].name}.docx`
            const documentUrl = `${API_BASE}/document/upload.docx?token=${tokens.accessToken}&filename=${filename}&upload=${transactionData.upload}&locked=true`
            window.open(documentUrl, '_blank')
        })
    }

    const onClickCompare = () => {
        navigate(
            `/compare/${transaction._id}?versions=` +
                Array.from(selected).join(','),
        )
    }

    const commands = [
        {
            key: 'back',
            text: 'Back',
            iconProps: { iconName: 'Back' },
            onClick: () => navigate(-1),
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: loadData,
        },
        {
            key: 'compare',
            text: 'Compare',
            iconProps: { iconName: 'BranchCompare' },
            disabled: selected.size < 2,
            onClick: onClickCompare,
        },
        // Disable export for now
        // {
        //     key: 'export',
        //     text: 'Export',
        //     iconProps: { iconName: 'Export' },
        // },
        {
            key: 'viewDocumet',
            text: 'View Document',
            iconProps: { iconName: 'EntryView' },
            disabled:
                selected.size !== 1 ||
                !transactionData ||
                !transactionData.upload,
            onClick: onDocumentView,
        },
        // Disable recall for now
        // {
        //     key: 'recall',
        //     text: 'Recall',
        //     iconProps: { iconName: 'Undo' },
        //     disabled: true,
        // },
    ].filter((a) => !!a)

    return (
        <>
            <Stack horizontal verticalAlign='center'>
                <StackItem>
                    <CommandBar items={commands} />
                </StackItem>
                <Toggle
                    label='Show Archive'
                    inlineLabel
                    value={hideArchive}
                    styles={{ root: { margin: 0 } }}
                    onChange={() => setHideArchive(!hideArchive)}
                />
                <Toggle
                    label='Show Hidden'
                    inlineLabel
                    value={showHidden}
                    styles={{ root: { margin: 0 } }}
                    onChange={() => setShowHidden(!showHidden)}
                />
            </Stack>

            <Stack horizontal={true} tokens={{ childrenGap: 16 }}>
                <div>
                    <Pipeline
                        stages={pipelineData}
                        pipeline={pipeline}
                        selected={Array.from(selected)}
                        onSelected={onSelected}
                        onClick={onClickRow}
                        showHidden={showHidden}
                    />
                </div>
                <div>
                    {showDetails === 'save-details' ? (
                        <SaveDetails
                            domainTemplate={template}
                            transaction={transaction}
                            transactionHistory={transactionHistory}
                            // template={cutdownDomainTemplateContent}
                            data={transactionData}
                            fields={fields}
                        />
                    ) : null}
                    {showDetails === 'stage-details' ? (
                        <StageDetails
                            transaction={transaction}
                            activeStage={activeStage}
                            pipelineData={pipelineData}
                            transactionHistory={transactionHistory}
                            // template={cutdownDomainTemplateContent}
                            // data={transactionData}
                            // fields={fields}
                        />
                    ) : null}
                </div>
            </Stack>
        </>
    )
}

const TransactionOverviewWrap = (props) => {
    return (
        <FormProvider>
            <TransactionOverview {...props} />
        </FormProvider>
    )
}

const SaveDetails = ({
    transaction,
    transactionHistory,
    data,
    fields,
    domainTemplate,
}) => {
    const sm = useStateMachine()
    const initTime = Date.now()
    const currentActiveStage = transaction.stages[data.stage] || {}
    const user = data.createdBy
    const username = user ? `${user.firstName} ${user.lastName}` : ''
    const date = formatDateTime(data.created)
    const meta = user ? `Saved by ${username} on ${date}` : ''
    const [template, setTemplate] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [values] = React.useState(new Values())

    console.log('currentActiveStage', currentActiveStage)

    React.useEffect(() => {
        ;(async () => {
            console.log('SaveDetails loading')
            setLoading(true)

            values.clear()
            // @TODO: multi-templates
            const templateId = currentActiveStage.templates[0]
            let _template = null

            const isDocumentDataStage = ['document', 'data'].includes(
                currentActiveStage.kind,
            )

            values.applyHistory(transactionHistory, data._id)

            if (!isDocumentDataStage) {
                // Cutdown template
                const templateClone = cloneObject(domainTemplate)
                const cutdownDomainTemplateContent = reduceTemplate(
                    domainTemplate.content.contents,
                    values.data,
                )
                templateClone.content.contents = cutdownDomainTemplateContent
                _template = templateClone
            } else {
                _template = await getTemplate(templateId)
            }

            console.log('SaveDetails loaded', _template)
            setTemplate(_template)

            // React.useMemo(() => {
            sm.initData({
                template: _template,
                transactionData: { fieldData: values.toData() },
            })

            // }, [values.data, templateClone])

            setLoading(false)
        })()
    }, [currentActiveStage.templates, data])

    return (
        <>
            <StageHeader stage={currentActiveStage} />
            {meta ? <p>{meta}</p> : null}
            {!loading ? (
                <Template
                    initTime={initTime}
                    contents={template.content.contents}
                    fields={fields}
                    readonly={true}
                />
            ) : null}
        </>
    )
}

const StageDetails = ({ transaction, activeStage, transactionHistory }) => {
    console.log(activeStage)
    const stageId = activeStage.data.stageId
    const currentActiveStage = transaction.stages[stageId]
    const item = activeStage
    console.log('currentActiveStage', currentActiveStage)

    let content = null
    const { kind } = currentActiveStage
    if (kind === 'docusign') {
        const docusignDataHistory = transactionHistory
            .filter((history) => {
                return history.stage === stageId && history.type === 'docusign'
            })
            .slice(-1)[0]

        console.log(docusignDataHistory, transactionHistory)

        content = docusignDataHistory ? (
            <DocusignOverview
                transaction={transaction}
                docusignData={docusignDataHistory.docusignData}
            />
        ) : null
    }

    if (kind === 'approve') {
        const docusignDataHistory = transactionHistory
            .filter((history) => {
                return history.stage === stageId && history.type === 'approve'
            })
            .slice(-1)[0]

        console.log(docusignDataHistory, transactionHistory)

        content = docusignDataHistory ? (
            <DocusignOverview
                transaction={transaction}
                docusignData={docusignDataHistory.approveData}
            />
        ) : null
    }

    return (
        <>
            <StageHeader stage={currentActiveStage} status={item.data.status} />
            {content}
        </>
    )
}

const StageHeader = ({ stage, status }) => {
    if (!stage) return null
    return (
        <>
            <Text variant='xxLarge'>{stage.title}</Text>
            <Stack vertical>
                <Text>Type: {stage.kind}</Text>
                {status ? <Text>Status: {status}</Text> : null}
            </Stack>
        </>
    )
}

export default TransactionOverviewWrap
