/* global refreshTokens */
import {
    getData,
    refreshToken,
    authPost,
    authGet,
    authPatch,
    API_BASE,
} from '../common.js'

export const getTransactions = (query, limit) =>
    authGet({
        url: '/transaction',
        params: {
            q: query ? JSON.stringify(query) : undefined,
            sort: '-created',
            limit,
        },
    }).then(getData)

export const getTransactionsStats = () =>
    authGet({
        url: '/transaction/stats',
    }).then(getData)

export const getTransactionsWorkflowStats = () =>
    authGet({
        url: '/transaction/stats/workflow',
    }).then(getData)

export const getTransaction = (transID) =>
    authGet({ url: `/transaction/${transID}` })
        .catch((err) => {
            throw err
        })
        .then(getData)

export const getTransactionActivity = (transID) =>
    authGet({ url: `/transaction/${transID}/activity` })
        .catch((err) => {
            throw err
        })
        .then(getData)

export const getTransactionFiles = (transID) =>
    authGet({ url: `/transaction/${transID}/files` })
        .catch((err) => {
            throw err
        })
        .then(getData)

export const postTransactionFileTags = (transID, fileID, tags = []) =>
    authPost({
        url: `/transaction/${transID}/files/${fileID}/tags`,
        data: { tags },
        json: true,
    }).then(getData)

export const downloadTransactionFile = (transID, fileId) => {
    refreshTokens().then(() => {
        const url = `${API_BASE}/transaction/${transID}/files/${fileId}/download?token=${refreshToken}`
        window.open(url, '_blank')
    })
}

export const downloadUpload = (filename, upload) => {
    refreshTokens().then(() => {
        const url = `${API_BASE}/upload?upload=${upload}&filename=${filename}&token=${refreshToken}`
        window.open(url, '_blank')
    })
}

export const downloadTransactionFileAll = (transID) => {
    refreshTokens().then(() => {
        const url = `${API_BASE}/transaction/${transID}/files/download-all?token=${refreshToken}`
        console.log(url)
        window.open(url, '_blank')
    })
}

export const advanceTransaction = (transID, nextRole = null) =>
    nextRole
        ? authPost({
              url: `/transaction/${transID}/advance`,
              json: true,
              data: { role: nextRole },
          }).then(getData)
        : authPost({
              url: `/transaction/${transID}/advance`,
              json: true,
          }).then(getData)

export const assignTransaction = (transID, role, payload) =>
    authPost({
        url: `/transaction/${transID}/assign`,
        data: { role, payload },
        json: true,
    }).then(getData)

export const commentTransaction = (transID, data) =>
    authPost({
        url: `/transaction/${transID}/comment`,
        data,
        json: true,
    }).then(getData)

export const nudgeTransaction = (transID, data) =>
    authPost({
        url: `/transaction/${transID}/nudge`,
        data,
        json: true,
    }).then(getData)

export const rollbackTransaction = (transID, data) =>
    authPost({
        url: `/transaction/${transID}/rollback`,
        data,
        json: true,
    }).then(getData)

export const cancelTransaction = (transID) =>
    authPost({
        url: `/transaction/${transID}/cancel`,
        json: true,
    }).then(getData)

export const uploadTransaction = (transID, data) =>
    authPost({
        url: `/transaction/${transID}/upload`,
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).then(getData)

export const updateTransactionParams = (transID, params) =>
    authPatch({
        url: `/transaction/${transID}/params`,
        json: true,
        data: params,
    }).then(getData)

export const transactionRolesRecall = (transID) =>
    authPost({
        url: `/transaction/${transID}/recall`,
        json: true,
    }).then(getData)

export const updateTransactionRolesRevoke = (transID, role) =>
    authPatch({
        url: `/transaction/${transID}/roles-revoke`,
        json: true,
        data: role,
    }).then(getData)

export const updateTransactionRole = (transID, values) => {
    // @TODO: handle access field
    return authPatch({
        url: `/transaction/${transID}/roles`,
        json: true,
        data: [values],
    }).then(getData)
}

export const createTransactionData = (payload) =>
    authPost({
        url: `/transactiondata`,
        json: true,
        data: payload,
    }).then(getData)

export const getTransactionHistory = (transID) =>
    authGet({ url: `/transaction/${transID}/history` }).then(getData)

export const getTransactionHistoryData = (transID, version) =>
    authGet({ url: `/transaction/${transID}/data/${version}` }).then(getData)

export const getTransactionDataById = (transactionDataID) =>
    authGet({ url: `/transactiondata/${transactionDataID}` }).then(getData)

export const getTransactionDataCompleteById = (transactionDataID) =>
    authGet({ url: `/transactiondata/${transactionDataID}/complete` }).then(
        getData,
    )

export const getTransactionData = (transID) =>
    authGet({ url: `/transaction/${transID}/data` }).then(getData)
