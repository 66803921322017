import React from 'react'

import {
    Modal,
    Text,
    Stack,
    DefaultButton,
    MessageBar,
    MessageBarType,
} from '@fluentui/react'
import Template from '../../components/template/Template'
import {
    updateTransactionParams,
    createTransactionData,
    advanceTransaction,
} from '../../api'

import TemplateContentCommandBar from './command/TemplateContentCommandBar'

import { getSetting } from '../../api/config'
import {
    isTransactionCreator,
    userInNextRealStage,
} from '../../lib/transaction'

const API_BASE = getSetting('API_BASE')

const CONFIG_USE_WORD_URLSCHEME = false

import { useForm } from '../../components/controls/useForm'
import { useStateMachine } from '../../hooks/useStateMachine.js'
import { useAuth } from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'

import SubmitModal from './modal/SubmitModal'
import ApproveModal from './modal/ApproveModal'
import RollbackModal from '../../components/template/RollbackModal'

const TemplateContent = ({
    transaction,
    transactionHistory,
    template,
    fields,
    currentStage = {},
    loadData = () => {},
    readOnly = false,
}) => {
    if (!transaction || !currentStage || !template) return null
    const transactionId = transaction ? transaction._id : null
    const { params = {} } = useForm()
    const sm = useStateMachine()
    const navigate = useNavigate()
    const { refreshAuth, userId } = useAuth()

    const [documentModalOpen, setDocumentModalOpen] = React.useState(false)
    const [submitModalOpen, setSubmitModalOpen] = React.useState(false)
    const [approveModalOpen, setApproveModalOpen] = React.useState(false)
    const [dirty, setDirty] = React.useState(sm.isDirty())
    const [rollbackModelOpen, setRollbackModelOpen] = React.useState(false)

    const isIngressStage = currentStage.kind === 'ingress'
    const isDataStage =
        currentStage.kind === 'data' || currentStage.kind === 'document'

    const showSend = true
    const hasRoles = currentStage.roles.length
    const isDirty = dirty

    const showSubmit = isIngressStage

    const currentRole = transaction.roles.find(
        ({ name }) => name === transaction.currentRole,
    )

    const isWithCurrentUser = currentRole
        ? userId === currentRole.user._id
        : false

    const isWithCurrentUserOrCreator =
        isWithCurrentUser || isTransactionCreator(transaction, userId)

    const canSend = hasRoles && isWithCurrentUserOrCreator
    const readOnlyAccess =
        hasRoles && currentRole && currentRole.access === 'view'
    const isReadOnly = transaction.readonly || readOnlyAccess || readOnly
    const isDocumentStage =
        template && template.type === 'document' && !!template.wordDocumentName

    const canSubmitIngress = !!params[currentStage.source]
    const canSubmit = isIngressStage && canSubmitIngress

    const lastSaveData =
        transactionHistory
            .filter(({ stage }) => stage === transaction.currentStage)
            .slice(-1)[0] || false

    const hasApprover = currentStage.approvers && currentStage.approvers.length
    const isValidApprover = hasApprover
        ? currentStage.approver.includes(transaction.currentRole)
        : true

    const approveAllowed = isDocumentStage
        ? lastSaveData && lastSaveData.upload && isValidApprover
        : isDataStage && isValidApprover

    const missingDocument =
        isDocumentStage &&
        (!lastSaveData || (lastSaveData && !lastSaveData.upload))

    const data = {
        missingDocument,
        approveAllowed,
        isValidApprover,
        hasApprover,
        canSubmit,
        canSubmitIngress,
        isDocumentStage,
        isReadOnly,
        readOnlyAccess,
        canSend,
        isWithCurrentUser,
        isTransactionCreator: isTransactionCreator(transaction, userId),
        userId,
        transaction,
    }

    console.log('Template Data', data)
    // console.log('TRX', transaction, {
    //     approveAllowed,
    //     missingDocument,
    //     hasApprover,
    //     isValidApprover,
    //     isDocumentStage,
    //     isDataStage,
    //     lastSaveData,
    // })

    // Try to find the latest version of the word document
    const lastHistoryWithTemplate = transactionHistory
        .filter(({ template: t }) => template._id === t)
        .slice(-1)[0]

    const hasExistingDocumentSaved = lastHistoryWithTemplate !== null

    const startDocumentDownload = () => {
        refreshAuth().then((tokens) => {
            const filename = `${template.title}.docx`

            const documentUrl = lastHistoryWithTemplate
                ? `${API_BASE}/document/${template.wordDocumentName}?token=${tokens.accessToken}&filename=${filename}&upload=${lastHistoryWithTemplate.upload}`
                : `${API_BASE}/document/${template.wordDocumentName}?token=${tokens.accessToken}&filename=${filename}&templateId=${template._id}&transactionId=${transactionId}`

            if (CONFIG_USE_WORD_URLSCHEME) {
                window.open(`ms-word:ofe|u|${documentUrl}`)
            } else {
                window.open(documentUrl, '_blank')
            }
        })
    }

    const onSubmit = async () => {
        console.log('onSubmit', currentStage)
        const paramName = currentStage.source
        if (params[paramName]) {
            await updateTransactionParams(transactionId, params)

            loadData()
        }
    }

    const onSend = () => setSubmitModalOpen(true)
    const onDocument = () => {
        setDocumentModalOpen(true)
        setTimeout(startDocumentDownload, 250)
    }

    const onApproveAssign = async (role) => {
        await advanceTransaction(transactionId, role).catch((err) => {
            console.log('Error advancing transaction', err)
        })
    }

    const onApprove = async () => {
        const result = userInNextRealStage(transaction, userId)
        if (!result) {
            // If user is not in next stages roles, then we need to
            // approve-assign. The stage will be approved, advanced and
            // moved to specified role.
            setApproveModalOpen(true)
            return
        }

        await advanceTransaction(transactionId)
            .then(loadData)
            .catch((err) => {
                console.log('Error advancing transaction', err)
            })
    }

    const onSave = async () => {
        const res = await sm.getSavePayload().catch((err) => {
            console.error('Errors', err)
        })

        if (!res) return
        const { fieldData } = res

        const payload = {
            domain: transaction.domain,
            transaction: transactionId,
            fieldData,
            stage: transaction.currentStage,
        }

        await createTransactionData(payload)

        loadData()
    }

    const onRollback = () => {
        setRollbackModelOpen(true)
    }

    const handleDismissModal = () => {
        setDocumentModalOpen(false)
        loadData()
    }

    const update = React.useCallback(() => {
        setDirty(sm.isDirty())
    })

    React.useEffect(() => {
        sm.ee.on('errors', update)
        return () => sm.ee.off('errors', update)
    }, [sm, update])

    const onBack = () => navigate(-1)

    const commandBarProps = {
        onRefresh: loadData,
        onSave,
        onBack,
        onCancel: loadData,
        onDocument,
        onSend,
        onSubmit,
        onApprove,
        onRollback,

        showCancel: isWithCurrentUserOrCreator && !isReadOnly,
        showSave: isWithCurrentUserOrCreator && isDataStage && !isReadOnly,
        showDocument:
            (isWithCurrentUserOrCreator && isDocumentStage) ||
            (hasExistingDocumentSaved && readOnlyAccess),
        showSubmit: isWithCurrentUserOrCreator && showSubmit && !isReadOnly,
        showSend: isWithCurrentUserOrCreator && showSend,
        showApprove:
            isWithCurrentUserOrCreator && isDataStage && isValidApprover,
        showRollback: isWithCurrentUserOrCreator,
        rollbackDisabled: transaction.currentStage === 0,

        cancelDisabled: !isDirty,
        documentDisabled: isDirty,
        submitDisabled: !canSubmit,
        sendDisabled: !canSend,
        approveDisabled: isDirty || !approveAllowed,
    }

    const messages = [
        // isReadOnly ? (
        //     <MessageBar key='read-only' isMultiline={false}>
        //         Read-only mode. Transaction is currently with{' '}
        //         {transaction.currentRole}
        //     </MessageBar>
        // ) : null,
        // !isReadOnly && missingDocument ? (
        //     <MessageBar
        //         key='doc-missing'
        //         isMultiline={false}
        //         actions={
        //             <MessageBarButton
        //                 iconProps={{ iconName: 'WordDocument' }}
        //                 key='open-document'
        //                 onClick={onDocument}>
        //                 Open Document
        //             </MessageBarButton>
        //         }>
        //         Document needs saving. Please open document and save to proceed.
        //     </MessageBar>
        // ) : null,
    ]

    return (
        <Stack tokens={{ childrenGap: 8 }}>
            <div id='command-bar'>
                <Stack horizontal>
                    <TemplateContentCommandBar {...commandBarProps} />
                    <Stack
                        horizontal
                        style={{ height: 0 }}
                        tokens={{ childrenGap: 10 }}>
                        {!isDirty && !isReadOnly && missingDocument ? (
                            <MessageBar messageBarType={MessageBarType.warn}>
                                Document needs saving
                            </MessageBar>
                        ) : null}
                        {isDirty && !isReadOnly ? (
                            <MessageBar messageBarType={MessageBarType.warn}>
                                Save needed
                            </MessageBar>
                        ) : null}
                    </Stack>
                </Stack>
            </div>
            <Text variant='xxLarge'>{currentStage.title}</Text>
            <Stack horizontal tokens={{ childrenGap: 8 }}>
                <Text>Current Role: {transaction.currentRole}</Text>
                {currentStage.approver ? (
                    <Text>Approver: {currentStage.approver}</Text>
                ) : null}
                <Text>
                    Your Roles:{' '}
                    {transaction.roles
                        .filter((role) => role.user._id === userId)
                        .map((role) => role.name)
                        .join(', ')}
                </Text>
            </Stack>
            {messages}
            <Template
                contents={template.content.contents}
                fields={fields}
                onItemInvoke={onSubmit}
                readonly={isReadOnly}
            />
            <Modal isOpen={documentModalOpen}>
                <Stack horizontalAlign='center'>
                    <h1>Document Open</h1>
                    <p>Refresh data on return.</p>
                    <DefaultButton onClick={handleDismissModal}>
                        Refresh
                    </DefaultButton>
                </Stack>
            </Modal>

            <SubmitModal
                isOpen={submitModalOpen}
                onClose={() => setSubmitModalOpen(false)}
                loadData={loadData}
                transaction={transaction}
            />

            <ApproveModal
                isOpen={approveModalOpen}
                onClose={() => setApproveModalOpen(false)}
                loadData={loadData}
                onApproveAssign={onApproveAssign}
                transaction={transaction}
            />

            <RollbackModal
                isOpen={rollbackModelOpen}
                onClose={() => setRollbackModelOpen(false)}
                transaction={transaction}
            />
        </Stack>
    )
}

export default TemplateContent
